import { Box, Container, Flex } from 'components/box';
import { css } from '@emotion/react';
import {
  ChevronLeft as IconLeft,
  ChevronRight as IconRight,
} from 'react-feather';
import { ReactNode, UIEvent, memo, useCallback, useRef, useState } from 'react';
import { debounce } from '../../../../util/debounce';

interface ScrollState {
  leftButtonActive: boolean;
  rightButtonActive: boolean;
}

const isScrollableElement = (
  element: HTMLElement | unknown
): element is HTMLElement =>
  typeof (element as HTMLElement).scrollLeft !== 'undefined';

const SCROLL_ACTION_OFFSET = 200;
const SCROLL_BUTTON_WIDTH = 35;

const ScrollButton = ({
  pos,
  isActive,
  onClick,
  children,
}: {
  pos: 'left' | 'right';
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
}) => (
  <button
    className={[pos, ...(isActive ? ['active'] : [])].join(' ')}
    onClick={onClick}
    css={theme => css`
      position: absolute;
      top: 0;
      height: 100%;
      width: ${SCROLL_BUTTON_WIDTH}px;
      padding: 0;
      background: ${theme.colors.offWhite};
      color: ${theme.colors.darkestGrey};
      box-shadow: 0px 0px 5px #cbcbed;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.2s ease;
      opacity: 0;
      pointer-events: none;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }

      &.active {
        opacity: 1;
        pointer-events: unset;
      }

      &:hover {
        background: ${theme.colors.lightGrey};
      }

      @media ${theme.mediaQueries.mobileOnly} {
        width: 25px;
      }
    `}
  >
    {children}
  </button>
);

const ScrollableInner = ({ children }: { children: ReactNode }) => {
  const [leftButtonActive, setLeftButtonActive] = useState(false);
  const [rightButtonActive, setRightButtonActive] = useState(true);
  const scrollRef = useRef<ScrollState>({
    leftButtonActive: false,
    rightButtonActive: true,
  });
  const scrollableElement = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    if (!isScrollableElement(e.target)) return;

    const { scrollLeft, scrollWidth, clientWidth } = e.target;

    const shouldShowLeft = scrollLeft > 0;
    const shouldShowRight = scrollWidth - scrollLeft - clientWidth > 0;

    if (shouldShowLeft !== scrollRef.current.leftButtonActive) {
      setLeftButtonActive(shouldShowLeft);
      scrollRef.current.leftButtonActive = shouldShowLeft;
    }
    if (shouldShowRight !== scrollRef.current.rightButtonActive) {
      setRightButtonActive(shouldShowRight);
      scrollRef.current.rightButtonActive = shouldShowRight;
    }
  }, []);

  const handleScrollLeft = useCallback(() => {
    if (!scrollableElement.current) return;

    const { scrollLeft } = scrollableElement.current;

    const nextScrollLeft = Math.max(scrollLeft - SCROLL_ACTION_OFFSET, 0);
    scrollableElement.current.scrollTo({
      left: nextScrollLeft,
      behavior: 'smooth',
    });
  }, []);

  const handleScrollRight = useCallback(() => {
    if (!scrollableElement.current) return;

    const { scrollLeft, scrollWidth } = scrollableElement.current;

    const nextScrollLeft = Math.min(
      scrollLeft + SCROLL_ACTION_OFFSET,
      scrollWidth
    );
    scrollableElement.current.scrollTo({
      left: nextScrollLeft,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Flex
        ref={scrollableElement}
        maxWidth="100%"
        onScroll={handleScroll}
        alignItems="center"
        css={theme => css`
          overflow-y: scroll;
          scroll-snap-type: x mandatory;
          scroll-padding: 0 40px;
          ::-webkit-scrollbar {
            display: none;
          }

          gap: ${theme.space[3]}px;
          @media ${theme.mediaQueries.tabletUp} {
            gap: ${theme.space[4]}px;
          }
        `}
      >
        {children}
      </Flex>

      <ScrollButton
        pos="left"
        isActive={leftButtonActive}
        onClick={handleScrollLeft}
      >
        <IconLeft />
      </ScrollButton>

      <ScrollButton
        pos="right"
        isActive={rightButtonActive}
        onClick={handleScrollRight}
      >
        <IconRight />
      </ScrollButton>
    </>
  );
};

export const WhiteBar = memo((props: { children: ReactNode }) => (
  <Flex
    width="100%"
    justifyContent="center"
    backgroundColor="offWhite"
    px={0}
    css={css`
      box-shadow: 0px 0px 5px #bbbbdd;
    `}
  >
    <Container
      py={0}
      css={css`
        position: relative;
        overflow: hidden;
      `}
    >
      <ScrollableInner {...props} />
    </Container>
  </Flex>
));
