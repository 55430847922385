import { useState, useCallback, useEffect } from 'react';
import Toast, { toastTransitionTimeMs } from 'components/toast';
import { removeToast } from '../../../../redux/global/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { css } from '@emotion/react';

/**
 * A delay used to ensure the component is rendered before we try to show the toast.
 * Without it the animation won't go off or will appear wonky.
 */
const approxRenderTimeMs = 15;

const Toaster = () => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toasts = useSelector((state: RootState) => state.global.toasts);
  const firstToast = toasts.length === 0 ? false : toasts[0];

  const closeToast = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(removeToast(0));
      setIsActive(false);
    }, toastTransitionTimeMs);
  }, [dispatch]);

  useEffect(() => {
    if (firstToast && !isActive) {
      setTimeout(() => {
        setIsActive(true);
        setIsOpen(true);
      }, approxRenderTimeMs);

      if (firstToast.timeout && firstToast.timeout !== -1) {
        setTimeout(closeToast, firstToast.timeout);
      }
    }
  }, [closeToast, firstToast, isActive]);

  if (!firstToast) {
    return null;
  }

  return (
    <div
      css={css`
        z-index: 200;
      `}
    >
      <Toast variant={firstToast.variant} isOpen={isOpen} onClose={closeToast}>
        {firstToast.body}
      </Toast>
    </div>
  );
};

export default Toaster;
